<template>
	<div class="depart-box">
		<div class="top-box">
			<div>
				<label class="label" style="margin-left:6px;">关键字1</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字1"></el-input>
				<label class="label">关键字2</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey2" placeholder="请输入关键字2"></el-input>
				<label class="label" style="margin-left:5px;">排除项</label>
				<el-input class="search-input" size="small" type="text" v-model="searchExcludeKey" placeholder="请输入排除项"></el-input>
				<div style="margin-top:10px;">
					<label class="label">是否通过</label>
					<el-select class="search-input" size="small" v-model="passFlag" filterable placeholder="请选择">
						<el-option value="" label="全部"></el-option>
						<el-option value="2" label="未通过"></el-option>
						<el-option value="3" label="通过"></el-option>
					</el-select>
					<label class="label" style="margin-left:22px;">区域</label>
					<el-select class="search-input" size="small" v-model="areaId" filterable placeholder="请选择">
						<el-option value="" label="全部"></el-option>
						<el-option
							v-for="item in areaList"
							:key="item.id"
							:label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
					<label class="label">培训日期</label>
					<el-date-picker
						v-model="timeList"
						type="daterange"
						value-format="yyyy-MM-dd"
						range-separator="~"
						start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
					<div class="top-left-box">
						<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearchUser">
						<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-box">
			<div class="left-box">
				<div class="type-box">
					<button class="btn1" :class="type == '1' ? 'current' : ''" @click="handleType('1')">按区域</button>
					<button class="btn1" :class="type == '2' ? 'current' : ''" @click="handleType('2')">按课程</button>
				</div>
				<div :style="height" class="tree-box">
					<el-tree
						ref="tree"
						node-key="id"
						:data="treeData"
						:props="defaultProps"
						lazy
						:load="loadNode"
						:expand-on-click-node="false"
						@node-click="handleNodeClick">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<el-tooltip class="item" effect="light" :content="node.label+'('+data.extAttr1+'/'+data.extAttr2+')'" placement="right">
								<span class="tree-tips">{{node.label}}(<span style="color:red;">{{data.extAttr1}}</span>/<span style="color:blue;">{{data.extAttr2}}</span>)</span>
							</el-tooltip>
						</span>
					</el-tree>
				</div>
			</div>
			<div class="right-box">
				<div class="menu-box">
					<el-button class="menu-btn" type="primary" icon="el-icon-menu" @click="menuFlag = !menuFlag"></el-button>
					<ul class="menu-ul" v-show="menuFlag">
						<li><el-checkbox v-model="areaNameFlag" @change="userList = userList">区域</el-checkbox></li>
						<li><el-checkbox v-model="cityNameFlag" @change="userList = userList">省市</el-checkbox></li>
						<li><el-checkbox v-model="companyCodeFlag" @change="userList = userList">公司编码</el-checkbox></li>
						<li><el-checkbox v-model="companyNameFlag" @change="userList = userList">公司名称</el-checkbox></li>
						<li><el-checkbox v-model="stuIdFlag" @change="userList = userList">学员编码</el-checkbox></li>
						<li><el-checkbox v-model="stuNameFlag" @change="userList = userList">学员名称</el-checkbox></li>
						<li><el-checkbox v-model="courseCodeFlag" @change="userList = userList">课程编码</el-checkbox></li>
						<li><el-checkbox v-model="courseNameFlag" @change="userList = userList">课程名称</el-checkbox></li>
						<li><el-checkbox v-model="startTimeFlag" @change="userList = userList">考试时间</el-checkbox></li>
						<li><el-checkbox v-model="stuScoreFlag" @change="userList = userList">分数</el-checkbox></li>
						<li><el-checkbox v-model="passFlagFlag" @change="userList = userList">状态</el-checkbox></li>
						<li><el-checkbox v-model="platFlag" @change="userList = userList">来源</el-checkbox></li>
					</ul>
					<el-button class="down-btn menu-btn" type="primary" v-if="perms.includes('train:history:onlineExport')" @click="handleDownload">导出</el-button>
				</div>
				<div class="table-box">
					<el-table
						:data="userList"
						:row-class-name="tableRowClassName"
						:header-cell-style="{color:'#444'}"
						border
						style="width: 100%">
						<el-table-column
							prop="areaName"
							v-if="areaNameFlag"
							show-overflow-tooltip
							label="区域">
						</el-table-column>
						<el-table-column
							prop="cityName"
							v-if="cityNameFlag"
							show-overflow-tooltip
							label="省市">
						</el-table-column>
						<el-table-column
							prop="companyCode"
							v-if="companyCodeFlag"
							show-overflow-tooltip
							width="80"
							label="公司编码">
						</el-table-column>
						<el-table-column
							prop="companyName"
							v-if="companyNameFlag"
							show-overflow-tooltip
							label="公司名称">
						</el-table-column>
						<el-table-column
							prop="stuId"
							v-if="stuIdFlag"
							show-overflow-tooltip
							width="80"
							label="学员编码">
						</el-table-column>
						<el-table-column
							prop="stuName"
							v-if="stuNameFlag"
							show-overflow-tooltip
							width="80"
							label="学员名称">
						</el-table-column>
						<el-table-column
							prop="courseCode"
							v-if="courseCodeFlag"
							show-overflow-tooltip
							label="课程编码">
						</el-table-column>
						<el-table-column
							prop="courseName"
							v-if="courseNameFlag"
							show-overflow-tooltip
							label="课程名称">
						</el-table-column>
						<el-table-column
							label="考试时间"
							align="center"
							v-if="startTimeFlag"
							show-overflow-tooltip>
							<template slot-scope="scope">
								<span>{{scope.row.examTime ? $moment(new Date(scope.row.examTime)).format('YYYY/MM/DD') : ''}}</span>
							</template>
						</el-table-column>
						<el-table-column
							prop="stuScore"
							v-if="stuScoreFlag"
							width="50"
							label="分数">
						</el-table-column>
						<el-table-column
							label="状态"
							align="center"
							v-if="passFlagFlag"
							width="80">
							<template slot-scope="scope">
								<span v-if="scope.row.passFlag == 2">未通过</span>
								<span v-if="scope.row.passFlag == 3">通过</span>
							</template>
						</el-table-column>
						<el-table-column
							label="来源"
							align="center"
							v-if="platFlag"
							width="60">
							<template slot-scope="scope">
								<span v-if="scope.row.plat == 1">PC</span>
								<span v-if="scope.row.plat == 2">公众号</span>
							</template>
						</el-table-column>
						<el-table-column
							label="操作"
							width="130">
							<template slot-scope="scope">
								<button v-if="scope.row.passFlag == 3 && perms.includes('train:history:onlineCertificate')" class="btn-blue" @click="handleCE(scope.row)">CE查看</button>
								<button v-if="scope.row.passFlag == 3 && perms.includes('train:history:onlineCertificate')" class="btn-blue" @click="handlePdf(scope.row)">CE证书下载</button>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination-wrapper pa25" v-if="userList.length > 0">
						<Pagination
							:page.sync="pageNo"
							:pageSize.sync="pageSize"
							:total="total"
							@sizeChange="handleSizeChange"
							@currentChange="handleCurrentChange">
						</Pagination>
					</div>
				</div>
			</div>
		</div>
		<Certificate ref="ceData"></Certificate>
		<Cepdf ref="cePdf"></Cepdf>
	</div>
</template>
<script>
import Pagination from '@/components/Pagination'
import Certificate from '@/components/Certificate'
import Cepdf from '@/components/CePdf'
export default {
	components: {
		Pagination,
		Certificate,
		Cepdf
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			type: '1',//1区域 2课程
			searchKey: '',
			searchKey2: '',
			searchExcludeKey: '',
			passFlag: '',//2未通过 2通过
			areaId: '',
			areaList: [],
			timeList: ['',''],
			pageNo: 1,
			pageSize: 10,
			total: 0,
			// 分组下的人员
			userList: [],
			// 分组树结构
			treeData: [],
			// 点击选择的当前节点数据
			currentObj: {},
			defaultProps: {
				children: 'children',
				label: 'name',
          		isLeaf: 'leaf'
			},
			height: '',
			menuFlag: false,
			areaNameFlag: false,
			cityNameFlag: false,
			companyCodeFlag: true,
			companyNameFlag: true,
			stuIdFlag: true,
			stuNameFlag: true,
			courseCodeFlag: false,
			courseNameFlag: true,
			startTimeFlag: false,
			stuScoreFlag: true,
			passFlagFlag: true,
			platFlag: true
		}
	},
	created() {
		this.getAreaList();
	},
	mounted() {
		this.height = 'height:' + (window.innerHeight - 180) + 'px;'
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({ row, rowIndex }) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 获取区域数据
		getAreaList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleType(type) {
			if(type == this.type){
				return
			}
			this.userList = [];
			this.pageNo = 1;
			// 区域
			if(type == '1') {
				this.type = '1';
				this.getAreaTree();
			}
			//课程
			else {
				this.type = '2';
				this.getCourseTree();
			}
		},
		// 获取组织树
		getAreaTree() {
			let that = this;
			that.$request.post(
				"getAreaTreeOnline",
				true,
				{
					lineFlag: '1',
					active: '1'
				},
				function (r) {
					if (r.code == "0") {
						that.treeData = r.data;
						that.currentObj = r.data[0];
						that.getDepartUserList(that.currentObj);
						setTimeout(() => {
							that.$refs.tree.setCurrentKey('999999999');
						}, 100);
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		getCourseTree() {
			let that = this;
			that.$request.post(
				"getCourseTreeOnline",
				true,
				{
					lineFlag: '1',
					active: '1'
				},
				function (r) {
					if (r.code == "0") {
						that.treeData = r.data;
						that.currentObj = r.data[0];
						that.getDepartUserList(that.currentObj);
						setTimeout(() => {
							that.$refs.tree.setCurrentKey('999999999');
						}, 100);
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		loadNode(node,resolve) {
			let that = this;
			that.$request.post(
				that.type == '1' ? "getAreaTreeOnline" : 'getCourseTreeOnline',
				true,
				{
					lineFlag: '1',
					active: '1',
					level: node.level == 0 ? '' : node.data['level'],
					id:  node.level == 0 ? '' : node.data['id']
				},
				function (r) {
					if (r.code == "0") {
						if(r.data[0].id == "999999999") {
							that.currentObj = r.data[0];
							that.getDepartUserList(that.currentObj);
							setTimeout(() => {
								that.$refs.tree.setCurrentKey('999999999');
							}, 100);
						}
						return resolve(r.data)
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 点击节点（部门）
		handleNodeClick(data,node,el) {
			this.currentObj = data;
			this.getDepartUserList(data);
		},
		// 获取部门用户
		getDepartUserList(data){
			let that = this;
			that.$request.post(
				that.type == '1' ? "getHistoryAreaList" : "getHistoryCourseList",
				true,
				{
					lineFlag: '1',
					active: '1',
					id: data.id,
					level: data.level,
					pid: data.pid,
					plevel: data.plevel,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					searchKey2: that.searchKey2,
					searchExcludeKey: that.searchExcludeKey,
					passFlag: that.passFlag,
					areaId: that.areaId,
					startTime: that.timeList ? that.timeList[0] : '',
					endTime: that.timeList ? that.timeList[1] : ''
				},
				function (r) {
					if (r.code == "0") {
						that.userList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 重置查询条件
		handleReset() {
			if(JSON.stringify(this.currentObj) == '{}') {
				this.$message.error('请先选择区域或课程')
				return
			}
			this.pageNo = 1;
			this.searchKey = '';
			this.searchKey2 = '';
			this.searchExcludeKey = '';
			this.passFlag = '';
			this.areaId = '';
			this.timeList = [];
			this.getDepartUserList(this.currentObj);
		},
		handleDownload() {
			if(JSON.stringify(this.currentObj) == '{}') {
				this.$message.error('请先查询再导出')
				return
			}
			let that = this
			that.$request.post(
				that.type == '1' ? "areaTreeDataExportOnline" : "courseTreeDataExportOnline",
				true,
				{
					lineFlag: '1',
					active: '1',
					id: that.currentObj.id,
					level: that.currentObj.level,
					pid: that.currentObj.pid,
					plevel: that.currentObj.plevel,
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					searchKey2: that.searchKey2,
					searchExcludeKey: that.searchExcludeKey,
					passFlag: that.passFlag,
					areaId: that.areaId,
					startTime: that.timeList[0],
					endTime: that.timeList[1]
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 搜索用户
		handleSearchUser() {
			if(JSON.stringify(this.currentObj) == '{}') {
				this.$message.error('请先选择区域或课程')
				return
			}
			this.pageNo = 1;
			this.getDepartUserList(this.currentObj);
		},
		handleSizeChange() {
			this.getDepartUserList(this.currentObj);
		},
		handleCurrentChange() {
			this.getDepartUserList(this.currentObj);
		},
		handleCE(dt) {
			dt.agentName = dt.companyName;
			dt.userName = dt.stuName;
			dt.trainStartTime = dt.startTime;
			dt.trainEndTime = dt.endTime;
			this.$refs.ceData.init(dt);
		},
		handlePdf(dt) {
			dt.agentName = dt.companyName;
			dt.userName = dt.stuName;
			dt.trainStartTime = dt.startTime;
			dt.trainEndTime = dt.endTime;
			this.$refs.cePdf.download(dt);
		}
	},

}

</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.top-box{
	border-bottom: 1px solid #ddd;
	box-sizing: border-box;
	padding-bottom: 5px;
	margin-bottom: 15px;
	height: 85px;
	.top-left-box{
		float: right;
	}
	.label{
		font-size: 15px;
		margin-right: 5px;
	}
	.search-input{
		width: 138px;
		margin: 0 10px;
	}
	.search-btn{
		width: 32px;
		height: 32px;
		vertical-align: -10px;
		cursor: pointer;
	}

	.refresh-btn{
		width: 32px;
		height: 32px;
		border: none;
		color: #117FFC;
		padding: 0;
		background-color: rgba(17, 127, 252, 0.2);
		font-weight: bold;
		font-size: 22px;
		vertical-align: -2px;
		margin-left: 5px;
	}
	/deep/ .el-range-editor.el-input__inner{
		width: 260px;
		height: 32px;
		line-height: 32px;
		padding: 0 3px;
	}
	/deep/ .el-date-editor .el-range-input,
	.el-date-editor .el-range-separator{
		font-size: 12px;
	}
}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn1{
	border: 1px solid rgba(226, 226, 226, 1);
	background-color: rgba(248, 251, 254, 1);
	cursor: pointer;
	outline: none;
	color: rgba(68, 68, 68, 1);
	border-radius: 3px;
	width: 95px;
	margin-right: 10px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	&:last-child{
		margin-right: 0;
	}
}
.current{
	color: rgba(17, 127, 252, 1);
	background-color: rgba(17, 127, 252, 0.2);
	border: none;
}
.type-box{
	margin-bottom: 10px;
}
.bottom-box{
	display: flex;
	.left-box{
		width: 200px;

	}
	.right-box{
		flex: 1;
		box-sizing: border-box;
		padding-left: 15px;
	}
}
.tree-box{
	width: 100%;
	box-shadow: 4px 1px 13px 2px rgba(193, 193, 193, 0.23);
	overflow-x: hidden;
	overflow-y: auto;
}
.tree-tips{
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 140px;
}
.menu-box{
	height: 40px;
	position: relative;
	.menu-btn{
		float: right;
		margin-bottom: 5px;
	}
	/deep/ .el-button--primary{
		padding: 6px 8px;
		font-size: 15px;
	}
	.down-btn{
		margin-right: 15px;
		font-size: 15px;
	}
}
.menu-ul{
	position: absolute;
	right: 0;
	top: 40px;
	background-color: #fff;
	z-index: 1;
	border: 1px solid #ddd;
	border-radius: 3px;
	box-sizing: border-box;
	padding: 15px;
	width: 150px;
	li{
		list-style: none;
		box-sizing: border-box;
		padding: 10px;
		border-bottom: 1px solid #eee;
	}
}
.table-box {
	/deep/ .el-table {
		table {
			tr {
				th {
					font-size: 12px;
					padding: 4px 0;
				}
				td {
					font-size: 12px;
					padding: 4px 0;
				}
			}
		}
	}
}

</style>
<style>
.el-tree-node:focus > .el-tree-node__content {
	background-color:rgba(199, 227, 249, 0.55) !important;
}
.el-tree-node__content:hover {
	background-color:rgba(199, 227, 249, 0.55);
}
.el-tree-node.is-current>.el-tree-node__content {
	background-color: rgba(199, 227, 249, 0.55);
}
</style>

